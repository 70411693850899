import React from 'react'

export default function Commisions() {
    return (
        <div className="w-full  p-5">
            <div className="bg-pinkSemiDark p-12 rounded-2xl shadow-xl">
                <div className="text-xl text-pinkDark font-bold mb-4 text-center">ABOUT COMMISSIONS</div>
                <div className="text-md font-medium text-indigo">
                    <ul className="list-disc">
                        <li className="mb-3">If you withdraw a lot of $PEACH, the commission will increase.</li>
                        <li className="mb-3">The purpose of this is to maintain an economic balance in DW.</li>
                        <li className="mb-3">Therefore, this calculator has the function of showing the amount of commissions according to the tokens you wish to withdraw.</li>
                        <li className="mb-3">This way you will know the BNB amounts needed to withdraw your tokens before trying to do so.</li>
                        <li >More information on: <a href="https://docs.darlingwaifu.com/gameplay/farmer-mode/rewards" target="_blank" className="break-all text-blueLight  "> https://docs.darlingwaifu.com/gameplay/farmer-mode/rewards </a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
