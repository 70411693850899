import React from 'react'
import isologo from '../assets/vector/isologo.svg';

export default function LogoDarlingWaifu() {
    return (
        <div className="h-24 lg:h-0">
            <div className="fixed top-4 left-4 right-0 z-50">
                <div className="relative flex justify-center lg:justify-start">
                    <img src={isologo} alt="DarlingWaifu" className="w-72" />
                </div>
            </div>
        </div>
    )
}
