import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { injected } from "../utils/connectors";
import contractConnect from "../utils/contractStore"
export default function useCalculatorSwap() {

    //Modal Settings
    const [form, setForm] = useState({ slippage: "10", deadline: "1", })
    const [modal, setModal] = useState(false)

    //Input forms
    const [formSwap, setFormSwap] = useState({ inputPeach: "", inputBnb: "" })
    const [formCalculator, setFormCalculator] = useState({ inputPeach: "", inputBnb: "" })


    const [contractPeach, setContractPeach] = useState("")
    const [contractPancake, setContractPancake] = useState(null)
    const [commisionPeach, setCommisionPeach] = useState("0")
    const [currentPricePeach, setCurrentPricePeach] = useState(0)
    const [currentSelectedPeach, setCurrentSelectedPeach] = useState("0")
    const [expenditure, setExpenditure] = useState(null)
    const [balance, setBalance] = useState(null)
    const [ammountOut, setAmmountOut] = useState(null)
    const [approve, setApprove] = useState(false);


    const { library, activate, account, chainId, active, networkId } = useWeb3React()

    useEffect(() => {
        activate(injected)
    }, [])


    const ContractVars = {

        peach: "0x6D76F8A9f1500ADd43A3991F17820D6788a12F23",
        pancake: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        wbnb: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"

    }

    console.log(balance, expenditure)


    useEffect(() => {

        let getContract = async () => {

            try {


                let contractPeach = await contractConnect(ContractVars.peach, library, "peach")
                let contractPancake = await contractConnect(ContractVars.pancake, library, "pancake")
                contractPeach.methods.balanceOf(account).call({ from: account }).then((result) => setBalance((parseInt(result) / Math.pow(10, 18)).toFixed(0))).catch(err => console.log(err))
                contractPeach.methods.getExpenditure(account, 24).call({ from: account }).then((result) => setExpenditure(parseInt(result))).catch(err => console.log(err))
                contractPeach.methods.getCurrentPrice().call({ from: account }).then((result) => { setCurrentPricePeach(result) }).catch(err => console.log(err))

                setContractPancake(contractPancake)
                setContractPeach(contractPeach)

            } catch (error) {
                console.log(error)
            }
        }

        if (account) getContract()

    }, [account, chainId, library])

    useEffect(() => {
        if (chainId !== 56) {
            try {

                window.ethereum?.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: `0x38`,
                            chainName: "Binance Smart Chain",
                            nativeCurrency: {
                                name: "Binance Coin",
                                symbol: "BNB",
                                decimals: 18
                            },
                            rpcUrls: ['https://bsc-dataseed.binance.org/'],
                            blockExplorerUrls: ['https://bscscan.com']
                        }
                    ]
                });

            } catch (error) {
                console.log(error)
            }
        }
    }, [chainId])


    useEffect(() => {
        let intervalId;
        if (contractPancake) {

            intervalId = setInterval(() => {

                contractPancake.methods.getAmountsOut((10 ** 18).toString(), [ContractVars.peach, ContractVars.wbnb]).call({ from: account })
                    .then(result => { setAmmountOut(result[result.length - 1] / Math.pow(10, 18)) })
                    .catch(err => { console.log(err) })

            }, 1000)

        }

        return () => {
            clearInterval(intervalId)
        }

    }, [contractPancake])


    useEffect(() => {

        if (contractPeach) {
            contractPeach.methods.allowance(account, ContractVars.pancake).call({ from: account }).then((result) => setApprove(result === "0" ? false : true))
        }


    }, [contractPeach])



    const onChangeSwap = (event) => {


        console.log("testing", expenditure)

        if (event.target.value.trim("") === "") return setFormSwap({ inputPeach: "", inputBnb: "" });

        let _amount = (event.target.value * Math.pow(10, 18))

        let _commission = _amount / 50

        _amount -= _commission
        let tempAmount = _amount * 0.07

        let tempX = _amount * 0.93 * currentPricePeach

        let paidCommission = (expenditure * (50000 - (45000 * Math.pow(10, 25)) / (expenditure + Math.pow(10, 25))));
        let currentCommission = ((tempX + expenditure) * (50000 - (45000 * Math.pow(10, 25)) / ((tempX + expenditure) + Math.pow(10, 25))));
        let commisionPeach = ((currentCommission - paidCommission) / tempX)

        _commission = (_amount * 0.93 * commisionPeach) / 100000
        _amount = (_amount * 0.93) + tempAmount - _commission

        setFormSwap({ inputPeach: event.target.value, inputBnb: ammountOut * (_amount / Math.pow(10, 18)) })

    }

    const onChangeCalculator = (event) => {

        if (event.target.value.trim("") === "") return setFormCalculator({ inputPeach: "", inputBnb: "" });

        let _amount = (event.target.value * Math.pow(10, 18))

        let _commission = _amount / 50

        _amount -= _commission
        let tempAmount = _amount * 0.07

        let tempX = _amount * 0.93 * 1000

        let paidCommission = 0;
        let currentCommission = (tempX * (50000 - (45000 * Math.pow(10, 25)) / (tempX + Math.pow(10, 25))));
        let commisionPeach = ((currentCommission - paidCommission) / tempX)

        _commission = (_amount * 0.93 * commisionPeach) / 100000
        _amount = (_amount * 0.93) + tempAmount - _commission

        /*  let tempValue = event.target.value * 1000
         let percentCalculate = (50000 - ((45 * Math.pow(10, 28)) / ((tempValue * 0.9114) + Math.pow(10, 25)))) / 100000
         let calculateCommision = ((tempValue * 0.9114 * percentCalculate + (tempValue * 0.02)) / 10 ** 21)
  */

        setFormCalculator({
            inputPeach: event.target.value, inputBnb: (_amount / Math.pow(10, 18)).toFixed(2)
        })

    }


    const onSubmitBuy = async () => {

        if (contractPeach) {

            let timeStampDeadline = new Date();

            let tempPeach = formSwap.inputPeach.split('.')

            if (tempPeach.length === 1) tempPeach.push("")

            console.log(tempPeach[0] + tempPeach[1] + "0".repeat(18 - tempPeach[1].length), parseInt(formSwap.inputBnb * Math.pow(10, 18) * (100 - form.slippage) / 100).toString())

            await contractPancake.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(tempPeach[0] + tempPeach[1] + "0".repeat(18 - tempPeach[1].length), parseInt(formSwap.inputBnb * Math.pow(10, 18) * (90 - form.slippage) / 100).toString(), [ContractVars.peach, ContractVars.wbnb], account, parseInt((timeStampDeadline.getTime() / 1000) + (form.deadline * 60)).toString()).send({ from: account });
        }


    }

    const onSubmitApprove = () => {

        contractPeach.methods.approve(ContractVars.pancake, "9999999999999999999999999999999999999999").send({ from: account }).then((result) => setApprove(true)).catch(err => setApprove(false))

    }

    return { onChangeCalculator, formCalculator, account, formSwap, onSubmitBuy, onChangeSwap, modalProps: { form, setForm, modal, setModal }, slippage: form.slippage, approve, onSubmitApprove, balance }
}
