import React from 'react'

export default function ContractAudit() {
    return (
        <div className="fixed top-0 bottom-0 left-0 flex w-8 justify-center items-center z-50">
            <div className="-rotate-90 flex">
                <div className="text-lg font-semibold whitespace-nowrap cursor-pointer">Contract audit</div>
                <div className="text-lg font-semibold mx-5">|</div>
                <div className="text-lg font-semibold whitespace-nowrap cursor-pointer">Contract address</div>
            </div>
        </div>
    )
}
