import React, { useState } from 'react'
/* import { GrClose } from "react-icons/Gr"; */


export default function ModalSwap({ modal, setModal, form, setForm }) {


    const [error, setError] = useState(null)

    const closeModal = () => {
        setError(null)

        if (!form.slippage) return setError("Need Slippage Tolerance")
        if (!form.deadline) return setError("Need Transaction Deadline")

        setModal(false)

    }

    return (
        <div className={`fixed h-[100vh]  inset-0 z-[150]  bg-opacity-50 text-right ${!modal ? "hidden" : ""}`}>

            <div className="absolute inset-0 bg-gray-900 bg-opacity-60 max-h-[100vh]" style={{ backdropFilter: "blur(7px)" }}>

            </div>

            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:mt-0 scroll-hidden" tabindex="-1">



                <div className=" py-1 px-0 md:px-7 rounded-xl border bg-[rgba(255,255,255,0.85)]  ">
                    <div className='absolute top-8 right-8'>{/*  <GrClose onClick={closeModal} className="cursor-pointer" />  */} </div>

                    <p className='text-left text-[#000] text-lg mx-3 py-5 border-b-[1px] border-[rgba(141,141,141,0.72)] font-bold'>Settings</p>
                    {error && <p className="text-[#e64b4b] w-[100%] text-left pl-2"> <strong>{error} </strong> </p>}

                    <div>
                        <p className='text-left text-[#000] text-md mx-3 mt-7 mb-3 font-semibold'>Slippage Tolerance (Max 80%)</p>
                        <div className='flex items-center'>


                            <button onClick={() => { setForm({ ...form, slippage: "0.1" }) }} className='rounded-lg border bg-[#c5bdbcba] text-lg text-[#591830]  h-[40px] font-semibold p-1 mx-2  px-3'>0.1%</button>
                            <button onClick={() => { setForm({ ...form, slippage: "0.5" }) }} className='rounded-lg border bg-[#c5bdbcba] text-lg text-[#591830] h-[40px] font-semibold p-1 mx-2  px-3'>0.5%</button>
                            <button onClick={() => { setForm({ ...form, slippage: "1" }) }} className='rounded-lg border bg-[#c5bdbcba] text-lg text-[#591830] h-[40px] font-semibold p-1 mx-3 px-4'>1%</button>
                            <input onChange={(e) => { if (e.target.value < 100) setForm({ ...form, slippage: e.target.value }) }} value={form.slippage} type="number" max={100} className=' pl-2 rounded-lg outline-none mx-2 bg-[#c5bdbcba] h-[40px]' />
                            <p>%</p>
                        </div>

                    </div>
                    <div>

                        <p className='text-left text-[#000] text-md mx-3 mt-7 mb-3 font-semibold'>Transaction Deadline</p>
                        <div className='flex  justify-start items-center mb-4 border'>

                            <input value={form.deadline} onChange={(e) => { setForm({ ...form, deadline: e.target.value }) }} min={0} type="number" className=' pl-3 rounded-lg outline-none h-[40px] flex justify-start  w-[20%] mx-2 bg-[#c5bdbcba] p-1' />
                            <label className='text-[rgba(0,0,0,0.72)] text-md font-semibold '>Minutes</label>

                        </div>



                    </div>





                </div>
            </div>
        </div>
    )
}
